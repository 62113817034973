import React from "react";
import Layout from "../components/Layout";
import LocalizedLink from "../components/LocalizedLink";
import { FormattedMessage } from "react-intl";

const NotFoundPage = () => (
  <Layout>
    <section className="hero is-primary is-outlined is-bold">
      <div className="hero-body">
        <div className="container">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <div className="section is-centered">
                <h1 className="title title--404">
                  <FormattedMessage id="404Title" />
                </h1>
                <h2 className="subtitle button is-link">
                  <LocalizedLink to="/">Back to homepage</LocalizedLink>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
